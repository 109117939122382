import bg from "../assets/Grouddd.png";

function NotFound() {
    return (
        <div className="flex items-center text-center justify-center w-[100%] mdbvp:min-h-[45vh] xs:min-h-[20vh] min-h-[10vh] bg-[#05A5C8] lg:text-[40px] mdbvp:text-[25px] smbvp:text-[20px] text-[15px] font-extrabold text-white">
            Упс... проводятся технические работы
        </div>
    )
}

export default NotFound;